import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './features/login/Login';
import Signup from './features/login/Signup';
import { theme } from './app/styles';
import Statistics from './features/statistics/Statistics';
import TasksAdd from './features/tasks/TasksAdd';
import Tasks from './features/tasks/Tasks';
import TaskHistory from './features/tasks/TaskHistory';


function App()
{
  return (
    <div style={{ minHeight: "100vh"}}>
        <Router>
            <Routes>
                <Route path="/" element={<TasksAdd />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/task_history" element={<TaskHistory />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/tasks" element={<Tasks />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
