import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid, Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import Spacer from '../common/Spacer';
import Footer from '../appbar/Footer';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import ChartTotalsPerType from './ChartTotalsPerType';
import { getDataAsync, getTaskLogsForCharts } from '../../app/firebase';
import { useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';

function Statistics(props)
{
    const user = useSelector(getUser);
    const [data, setData] = useState([]);

    const [tasksList, setTasksList] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [tasksDictionary, setTasksDictionary] = useState({});
    const [selectedStartDate, setSelectedStartDate] = useState(dayjs().subtract(30, 'days'));
    const [selectedEndDate, setSelectedEndDate] = useState(dayjs());


    useEffect(() =>
    {
        getTaskList();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const getTaskList = () =>
    {
        getDataAsync('/users/' + user.id + '/tasks/', (tasks) =>
        {
            let newList = [];
            for (var task in tasks)
            {
                newList.push(tasks[task]);
            }
            newList.sort((a, b) =>
            {
                let nameA = a.title.toLowerCase();
                let nameB = b.title.toLowerCase();

                if (nameA < nameB)
                {
                    return -1;
                }
                if (nameA > nameB)
                {
                    return 1;
                }
                return 0;
            });
            setTasksDictionary(tasks);
            setTasksList(newList);
        });
    }

    const loadSelectedTasks = () =>
    {
        getTaskLogsForCharts('/users/' + user.id + '/logs/', dayjs(selectedStartDate).format("YYYY-MM-DD"), dayjs(selectedEndDate).format("YYYY-MM-DD"), tasks =>
        {
            let newData = [];
            for (var i = 0; i < tasks.length; i++)
            {
                if (selectedTasks.some(selectedTask => selectedTask.taskId === tasks[i].taskId))
                {
                    if (newData[tasks[i].taskId] == undefined)
                        newData[tasks[i].taskId] = [];
                    newData[tasks[i].taskId].push(tasks[i]);
                }
            }
            setData(newData);
        });
    }

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            <Container maxWidth="lg">
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="tags-standard"
                    options={tasksList}
                    getOptionLabel={(option) => option.title}
                    value={selectedTasks}
                    onChange={(e, v, r) =>
                    {
                        console.log("onChange");
                        setSelectedTasks(v);
                    }}
                    onBlur={e =>
                    {
                        console.log("onBlur");
                        loadSelectedTasks();
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Select tasks"
                            placeholder=""
                        />
                    )}
                />
                <Stack direction='row'>
                    <DatePicker
                        disableFuture
                        value={selectedStartDate}
                        onChange={(newValue) =>
                        {
                            setSelectedStartDate(newValue);
                        }}
                        onAccept={e =>
                        {
                            //getTasks(localTasksDictionary);
                        }}
                        format="D. MMM YYYY"
                    />
                    <DatePicker
                        disableFuture
                        value={selectedEndDate}
                        onChange={(newValue) =>
                        {
                            setSelectedEndDate(newValue);
                        }}
                        onAccept={e =>
                        {
                            //getTasks(localTasksDictionary);
                        }}
                        format="D. MMM YYYY"
                    />
                </Stack>
            </Container>
            <Spacer height={40} />
            <ChartTotalsPerType data={data} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} tasksDictionary={tasksDictionary} />
            <Spacer height={40} />
            <Footer />
        </div>
    );
}

export default withAuthentication(Statistics);
