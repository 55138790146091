import { Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { LineChart } from '@mui/x-charts/LineChart';

function ChartTotalsPerType(props)
{
    const [seriesData, setSeriesData] = useState([]);
    const [xLabels, setXLabels] = useState([]);

    useEffect(() =>
    {
        drawData();
        // cleanup function
        return () =>
        {
        };
    }, [props]);

    const drawData = () =>
    {
        console.log("DRAW Data");
        let rawData = props.data;
        let series = [];
        let counts = {};
        let newXLabels = [];
        let seriesIndex = 0;
        let days = props.selectedEndDate.diff(props.selectedStartDate, 'days');
        for (var i = 0; i < days; i++)
        {
            let currentDate = props.selectedStartDate.add(i, 'days').format("DD/MM");
            newXLabels.push(currentDate);
            counts[currentDate] = {};

            for (var taskLog in rawData)
            {

                for (var k = 0; k < rawData[taskLog].length; k++)
                {
                    if (counts[currentDate][rawData[taskLog][k].taskId] == undefined)
                        counts[currentDate][rawData[taskLog][k].taskId] = 0;

                    if (dayjs(rawData[taskLog][k].createdDate).format("DD/MM") == currentDate)
                        counts[currentDate][rawData[taskLog][k].taskId]++;
                }
            }
        }
        console.log(counts);

        for (var taskLog in rawData)
        {
            series[seriesIndex] = { data: [], label: props.tasksDictionary[rawData[taskLog][0].taskId].title, color: props.tasksDictionary[rawData[taskLog][0].taskId].color }
            for (var i = 0; i < days; i++)
            {
                let currentDate = props.selectedStartDate.add(i, 'days').format("DD/MM");
                if (counts[currentDate][rawData[taskLog][0].taskId] == 0)
                    series[seriesIndex].data.push(null);
                else
                    series[seriesIndex].data.push(counts[currentDate][rawData[taskLog][0].taskId]);
            }
            seriesIndex++;
        }


        setXLabels(newXLabels);
        setSeriesData(series);

        //console.log(series);
        
        //setData(chartData);
        /*
         * [
                    { data: pData, label: 'pv' },
                    { data: uData, label: 'uv' },
                ]
                */
    };

    return (
        <Paper>
            <LineChart
                xAxis={[{ scaleType: 'point', data: xLabels }]}
                series={seriesData}
                width={450}
                height={600}
                axisHighlight={{
                    x: 'line', // Or 'none', or 'band'
                    y: 'line', // Or 'none'
                }}
            />
        </Paper>
    );
}

export default ChartTotalsPerType;
