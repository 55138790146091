import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid, Autocomplete } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, pushData, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData, getTaskLogsForDate } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Footer from '../appbar/Footer';
import TaskLogAdminDialog from '../dialog/TaskLogAdminDialog';

function TaskHistoryItem(props)
{
    const user = useSelector(getUser);
    dayjs.extend(relativeTime);
    const taskDef = props.taskDef;
    const [isAdminDialogOpen, setIsAdminDialogOpen] = React.useState(false);

    const onTaskClick = () =>
    {
        console.log("onTaskClick");
        setIsAdminDialogOpen(true);
    }

    const onTimeChange = (isConfirmed, newTime) =>
    {
        console.log("onTimeChange");
        if (isConfirmed)
        {
            
            updateData('/users/' + user.id + '/logs/' + props.task.id, { createdDate: dayjs(newTime).format() }, () =>
            {
                props.onRefreshRequest();
            });
        }
        console.log("setIsAdminDialogOpen(false) = " + isAdminDialogOpen);
        setIsAdminDialogOpen(false);
    }

    const onDeleteTask = () =>
    {
        deleteData('/users/' + user.id + '/logs/' + props.task.id, props.onRefreshRequest);
    }

    return (
        <Paper elevation={4} style={{ backgroundColor: taskDef.color }} >
            <TaskLogAdminDialog isOpen={isAdminDialogOpen} task={props.task} callback={onTimeChange} onDeleteTask={onDeleteTask} />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack onClick={() => onTaskClick()} direction="row" alignItems="center" spacing={2}>
                        <Typography variant="caption">{dayjs(props.task.createdDate).format("DD-MM-YYYY @ HH:mm")}</Typography>
                </Stack>
            </Stack>
        </Paper>
    );
}

export default TaskHistoryItem;
