import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid, Autocomplete, Box, AppBar, Snackbar, Alert, AlertTitle } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, pushData, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData, getTaskLogsForDate } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Footer from '../appbar/Footer';
import TaskTodayItem from './TaskTodayItem';
import { DatePicker, DateTimePicker, MobileDatePicker } from '@mui/x-date-pickers';

function TasksAdd(props)
{
    dayjs.extend(relativeTime);
    const [historyTasksList, setHistoryTasksList] = useState([]);

    const [deletePatient, setDeletePatient] = useState({});
    const [confirmDeletePatientDialog, setConfirmDeletePatientDialog] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const filter = createFilterOptions();

    const [localTasksList, setLocalTasksList] = useState([]);
    const [localTasksDictionary, setLocalTasksDictionary] = useState({});
    const [localTaskData, setLocalTaskData] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [timerId, setTimerId] = useState("");
    
    useEffect(() =>
    {
        loadAll();
        //setTimerId(setInterval(() => { setSelectedDate(dayjs()) }, 1000));
        const handleVisibilityChange = () =>
        {
            if (document.visibilityState === 'visible')
            {
                setSelectedDate(dayjs());
                loadAll();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        // cleanup function
        return () =>
        {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            //clearInterval(timerId);
        };
    }, []);

    const loadAll = () =>
    {
        getTasksDefinitions();
    }

    const getTasksDefinitions = () =>
    {
        getDataAsync('/users/' + user.id + '/tasks/', (tasks) =>
        {
            let newList = [];
            for (var task in tasks)
            {
                newList.push(tasks[task]);
            }
            setLocalTasksList(newList);
            setLocalTasksDictionary(tasks);
            getTasks(tasks);
        });
    }

    const getTasks = (tasksDictionary) =>
    {
        getTaskLogsForDate('/users/' + user.id + '/logs/', (tasks) => drawTasks(tasks, tasksDictionary), 'createdDate', selectedDate.format("YYYY-MM-DD"), 200);
    }

    const drawTasks = (tasks, tasksDictionary) =>
    {
        let list = [];
        for (var i = 0; i < tasks.length; i++)
        {
            let task = tasks[i];
            list.push(<TaskTodayItem key={task.taskId + task.createdDate} task={task} tasksDictionary={tasksDictionary} onTaskDuplicateClick={onTaskDuplicateClick} onRefreshRequest={() => getTasks(tasksDictionary)} />);
        }
        setOpen(true);
        setHistoryTasksList(list);
        window.scrollTo(0, 0);
    };

    const onTaskClick = task =>
    {
        console.log("on task click");
    }

    const onTaskDuplicateClick = task =>
    {
        pushData('/users/' + user.id + '/logs/', { createdDate: dayjs().format(), taskId: task.taskId }, loadAll);
    }

    const onAddTask = (event) =>
    {
        if (localTaskData.isNew)
        {
            let taskId = uuidv4();
            let newTask = {
                taskId: taskId,
                createdDate: dayjs(selectedDate).format(),
                title: localTaskData.title,
                color: "ff0000"
            };

            writeData('/users/' + user.id + '/tasks/' + taskId, newTask, () =>
            {
                pushData('/users/' + user.id + '/logs/', { createdDate: dayjs(selectedDate).format(), taskId: taskId }, loadAll);
            });
        }
        else
        {
            pushData('/users/' + user.id + '/logs/', { createdDate: dayjs(selectedDate).format(), taskId: localTaskData.taskId }, loadAll);
        }
    }

    const [open, setOpen] = React.useState(false);

    const handleClick = () =>
    {
        setOpen(true);
    };

    const handleClose = (event, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setOpen(false);
    };

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} >
                    <AlertTitle>Success</AlertTitle>
                    Tasks updated
                </Alert>
            </Snackbar>
            {/*<ConfirmationDialog isOpen={confirmDeletePatientDialog} callback={onPatientDeleteConfirm} />*/}
            <Paper elevation={1}>
                <Stack spacing={1}>
                    {historyTasksList}
                </Stack>
                <Spacer height={180} />
            </Paper>
            <AppBar position="fixed" sx={{ top: "auto", bottom: 0, borderTop: "6px solid #4c4c4c" }}>
                <Spacer />
                <DatePicker
                    disableFuture

                    value={selectedDate}
                    onChange={(newValue) =>
                    {
                        setSelectedDate(newValue);
                    }}
                    onAccept={e =>
                    {
                        getTasks(localTasksDictionary);
                    }}
                    format="ddd, D. MMM YYYY @ HH:mm"
                    slots={{ textField: MyDatePickerLabel }}
                />
                
                <Stack sx={{ padding: 2 }} direction="row" spacing={3}>
                    <Autocomplete
                        value={localTaskData}
                        onChange={(event, newValue) =>
                        {
                            if (typeof newValue === 'string')
                            {
                                /*
                                setTaskTitle({
                                    title: newValue,
                                });
                                */
                            } else if (newValue && newValue.inputValue)
                            {
                                // Create a new value from the user input
                                setLocalTaskData({
                                    title: newValue.inputValue,
                                    isNew: true
                                });
                            } else
                            {
                                setLocalTaskData(newValue);
                            }
                        }}
                        filterOptions={(options, params) =>
                        {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.title);
                            if (inputValue !== '' && !isExisting)
                            {
                                filtered.push({
                                    inputValue,
                                    title: `Add "${inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="add-tasks-autocomplete"
                        options={localTasksList}
                        getOptionLabel={(option) =>
                        {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string')
                            {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue)
                            {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.title;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                        fullWidth
                        freeSolo
                        renderInput={(params) => (
                            <TextField {...params} label="What did you do?" />
                        )}
                    />
                    <Button variant="contained" size="small" onClick={onAddTask}>Add</Button>
                </Stack>
            </AppBar>
            <Footer />
        </div>
    );
}

export default withAuthentication(TasksAdd);

function MyDatePickerLabel(props)
{
    return (<Box {...props} >
        <Typography align="center" variant="h6">
            {props.value}
        </Typography>
    </Box>);
}