import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HexColorPicker } from "react-colorful";
import { TimeClock } from '@mui/x-date-pickers';
import * as dayjs from 'dayjs';
import { deleteData } from '../../app/firebase';

export default function TaskLogAdminDialog(props)
{
    const [localTime, setLocalTime] = React.useState(props.task.createdDate);

    const handleCancel = () =>
    {
        props.callback(false, localTime);
    };

    const handleConfirm = () =>
    {
        props.callback(true, localTime);
    };

    return (
            <Dialog
                open={props.isOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change time:"}
                </DialogTitle>
            <DialogContent>
                <Button color="secondary" onClick={e => props.onDeleteTask()}>DELETE</Button>
                <TimeClock defaultValue={dayjs(localTime)} ampm={false} onChange={setLocalTime} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
    );
}