import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid, Autocomplete } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, pushData, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData, getTaskLogsForDate } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import Spacer from '../common/Spacer';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Footer from '../appbar/Footer';
import TaskAdminItem from './TaskAdminItem';

function Tasks(props)
{
    dayjs.extend(relativeTime);
    const [historyTasksList, setHistoryTasksList] = useState([]);
    const user = useSelector(getUser);
    
    useEffect(() =>
    {
        loadAll();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const loadAll = () =>
    {
        getTasksDefinitions();
    }

    const getTasksDefinitions = () =>
    {
        getListOrderByChild('/users/' + user.id + '/tasks/', (tasks) =>
        {
            let newList = [];
            for (var task in tasks)
            {
                newList.push(<TaskAdminItem key={tasks[task].taskId} task={tasks[task]} />);
            }
            setHistoryTasksList(newList);
        },
        'title');
    }

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            {/*<ConfirmationDialog isOpen={confirmDeletePatientDialog} callback={onPatientDeleteConfirm} />*/}
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Typography align="center" variant="h5">
                        Tasks
                    </Typography>
                    <Paper elevation={1}>
                        <Stack spacing={1}>
                            {historyTasksList}
                        </Stack>
                    </Paper>
                </Stack>
            </Container>
            <Spacer height={80} />
            <Footer />
        </div>
    );
}

export default withAuthentication(Tasks);
