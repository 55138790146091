import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid, Autocomplete, Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, pushData, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData, getTaskLogsForDate } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Footer from '../appbar/Footer';
import TaskAdminColorDialog from '../dialog/TaskAdminColorDialog';
import { cloneDeep } from 'lodash';
import TaskAdminIconDialog from '../dialog/TaskAdminIconDialog';
import RestorePageIcon from '@mui/icons-material/RestorePage';

function TaskAdminItem(props)
{
    dayjs.extend(relativeTime);
    const user = useSelector(getUser);
    const navigate = useNavigate();

    const [taskDef, setTaskDef] = useState(props.task);
    const [isColorDialogOpen, setIsColorDialogOpen] = useState(false);
    const [isIconDialogOpen, setIsIconDialogOpen] = useState(false);

    const onTaskClick = () =>
    {

    }

    const onTaskIconChange = (isConfirmed, newIcon) =>
    {
        if (isConfirmed)
        {
            updateData('/users/' + user.id + '/tasks/' + taskDef.taskId, { icon: newIcon }, () =>
            {
                let newTaskDef = cloneDeep(taskDef);
                newTaskDef.icon = newIcon;
                setTaskDef(newTaskDef);
            });
        }
        setIsIconDialogOpen(false);
    }

    const onTaskColorChange = (isConfirmed, newColor) =>
    {
        if (isConfirmed)
        {
            updateData('/users/' + user.id + '/tasks/' + taskDef.taskId, { color: newColor }, () =>
            {
                let newTaskDef = cloneDeep(taskDef);
                newTaskDef.color = newColor;
                setTaskDef(newTaskDef);
            });
        }
        setIsColorDialogOpen(false);
    }

    const onTitleChange = newTitle =>
    {
        updateData('/users/' + user.id + '/tasks/' + taskDef.taskId, { title: newTitle }, () =>
        {
            let newTaskDef = cloneDeep(taskDef);
            newTaskDef.title = newTitle;
            setTaskDef(newTaskDef);
        });
    }

    const onTaskHistoryClick = () =>
    {
        navigate('/task_history', { state: { taskId: taskDef.taskId } });
    }

    return (
        <Paper elevation={4} onClick={() => onTaskClick()} >
            <TaskAdminColorDialog isOpen={isColorDialogOpen} taskDef={taskDef} callback={onTaskColorChange} />
            <TaskAdminIconDialog isOpen={isIconDialogOpen} taskDef={taskDef} callback={onTaskIconChange} />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box onClick={e => setIsColorDialogOpen(true)} style={{ backgroundColor: taskDef.color, width: '24px', height: '24px' }} />
                    <Box onClick={e => setIsIconDialogOpen(true)} style={{ backgroundColor: '#555555', width: '24px', height: '24px' }} >
                        <span className="material-symbols-outlined">{taskDef.icon}</span>
                    </Box>
                    <TextField variant="standard" defaultValue={taskDef.title} onBlur={e => onTitleChange(e.target.value)} />
                </Stack>
                <Button onClick={() => onTaskHistoryClick()}><RestorePageIcon sx={{ color: '#ffffff70' }} /></Button>
            </Stack>
        </Paper>
    );
}

export default TaskAdminItem;
