import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HexColorPicker } from "react-colorful";

export default function TaskAdminColorDialog(props)
{
    const [localColor, setLocalColor] = React.useState(props.taskDef.color);

    const handleCancel = () =>
    {
        props.callback(false, localColor);
    };

    const handleConfirm = () =>
    {
        props.callback(true, localColor);
    };

    return (
            <Dialog
                open={props.isOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Choose a task color"}
                </DialogTitle>
                <DialogContent>
                <HexColorPicker color={props.taskDef.color} onChange={color => setLocalColor(color)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
    );
}