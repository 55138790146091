import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HexColorPicker } from "react-colorful";
import { ImageList, ImageListItem, TextField, Typography } from '@mui/material';
import { MaterialIconsList } from './MaterialIconsList';

export default function TaskAdminIconDialog(props)
{
    const [selectedIcon, setSelectedIcon] = React.useState(props.taskDef.icon);
    const [searchText, setSearchText] = React.useState('');

    const handleIconClick = (icon) =>
    {
        setSelectedIcon(icon);
    };

    const filteredIcons = MaterialIconsList.filter(icon =>
        icon.icon.includes(searchText.toLowerCase())
    );

    const handleCancel = () =>
    {
        props.callback(false, selectedIcon);
    };

    const handleConfirm = () =>
    {
        props.callback(true, selectedIcon);
    };

    return (
            <Dialog
                open={props.isOpen}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Choose an icon"}
                </DialogTitle>
                <DialogContent>
                <span className="material-symbols-outlined">{selectedIcon}</span>
                <TextField
                    fullWidth
                    type="text"
                    placeholder="Search icons..."
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <ImageList sx={{ height: 450, overflowX: 'hidden' }} cols={4} rowHeight={100}>
                    {filteredIcons.map((item, index) => (
                        <ImageListItem sx={{ width: 80 }} key={item.icon} onClick={() => handleIconClick(item.icon)}>
                            <span className="material-symbols-outlined" style={{fontSize:42}}>{item.icon}</span>
                            {/*<Typography variant="caption">{item.icon}</Typography>*/}
                        </ImageListItem>
                    ))}
                </ImageList>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleConfirm} autoFocus>Confirm</Button>
                </DialogActions>
            </Dialog>
    );
}