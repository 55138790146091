import { Button, Container, Divider, Pagination, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, IconButton, TextField, Typography, Grid, Autocomplete, Box } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import TableBody from '@mui/material/TableBody';
import DeleteIcon from '@mui/icons-material/Delete';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import React, { useState, useEffect } from 'react';
import { writeData, getListOrderByChildEndBeforeLimit, pushData, stopListening, getDataAsync, startListening, getSearchPatients, getPatientsProtocols, deleteData, getListOrderByChild, updateData, getTaskLogsForDate, getTaskHistory } from '../../app/firebase';
import { withAuthentication } from '../login/withAuthentication';
import ResponsiveAppBar from '../appbar/ResponsiveAppBar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../user/UserSlice';
import ConfirmationDialog from '../dialog/ConfirmationDialog';
import Spacer from '../common/Spacer';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Footer from '../appbar/Footer';
import TaskTodayItem from './TaskTodayItem';
import { DatePicker, DateTimePicker, MobileDatePicker } from '@mui/x-date-pickers';
import TaskHistoryItem from './TaskHistoryItem';

function TaskHistory(props)
{
    dayjs.extend(relativeTime);
    const [historyTasksList, setHistoryTasksList] = useState([]);

    const [deletePatient, setDeletePatient] = useState({});
    const [confirmDeletePatientDialog, setConfirmDeletePatientDialog] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const filter = createFilterOptions();

    const [localTasksList, setLocalTasksList] = useState([]);
    const [localTasksDictionary, setLocalTasksDictionary] = useState({});
    const [localTaskData, setLocalTaskData] = useState("");
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const { state } = useLocation();
    const { taskId } = state;
    
    useEffect(() =>
    {
        loadAll();
        // cleanup function
        return () =>
        {
        };
    }, []);

    const loadAll = () =>
    {
        getTasksDefinitions();
    }

    const getTasksDefinitions = () =>
    {
        getDataAsync('/users/' + user.id + '/tasks/' + taskId, (task) =>
        {
            setLocalTaskData(task);
            getTasks(task);
        });
    }

    const getTasks = (task) =>
    {
        getTaskHistory('/users/' + user.id + '/logs/', taskId, (tasks) => drawTasks(tasks, task));
    }

    const drawTasks = (tasks, taskDef) =>
    {
        let list = [];
        for (var i = 0; i < tasks.length; i++)
        {
            let task = tasks[i];
            list.push(<TaskHistoryItem key={task.taskId + task.createdDate} task={task} taskDef={taskDef} onRefreshRequest={() => getTasks(localTasksDictionary)} />);
        }
        setHistoryTasksList(list);
    };

    return (
        <div>
            <ResponsiveAppBar />
            <Spacer />
            {/*<ConfirmationDialog isOpen={confirmDeletePatientDialog} callback={onPatientDeleteConfirm} />*/}
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <Paper elevation={4} style={{ backgroundColor: localTaskData.color }} >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <span className="material-symbols-outlined">{localTaskData.icon}</span>
                                <Stack direction="row">
                                    <Typography variant="h4" fullWidth>{localTaskData.title}</Typography>
                                    <Button onClick={e => { deleteData('/users/' + user.id + '/tasks/' + taskId, () => { navigate('/tasks') }) }}>DELETE</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Paper elevation={1}>
                        <Stack spacing={1}>
                            {historyTasksList}
                        </Stack>
                    </Paper>
                </Stack>
            </Container>
            <Spacer height={80} />
            <Footer />
        </div>
    );
}

export default withAuthentication(TaskHistory);